import { useCallback, useEffect, useState } from "react";
import jwt from 'jwt-decode';
import TokenService from "servicios/TokenService";
import { useLocalization } from "contexto/localization/useLocalization";

/**
 * 
 * @typedef {Object} Acceso
 * @property {string} name - Nombrel del acceso
 * @property {bool} write - Permite escritura
 */

/**
 * Obtiene el nombre del rol de acuerdo al idioma configurado
 * @callback GetNombreRol
 * @param {string} rol Nombre de rol
 * @return {string} Nombre de rol
 */

/**
 * Obtiene el nombre del acceso de acuerdo al idioma configurado
 * @callback GetNombreAcceso
 * @param {string} acceso Nombre de acceso
 * @return {string} Nombre de acceso
 */

/**
 * Obtiene si acceso es de escritura
 * @callback EsAccesoWrite
 * @param {string} acceso Nombre de acceso
 * @return {bool} Es de escritura
 */

/**
 * Gestor de roles del usuario
 * @return {[roles: string[], accesos: Acceso[], isLoading: bool, getNombreRol: GetNombreRol, getNombreAcceso: GetNombreAcceso, esAccesoWrite: EsAccesoWrite]}
 */
const useRoles = () => {
    const [roles, setRoles] = useState([]);
    const [accesos, setAccesos] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { strings } = useLocalization();

    const _getAcceso = (str = "") => {
        const nAcc = str.split("-");
        return {
            name: nAcc[0],
            write: nAcc.length > 0 ? (nAcc[1] === "F") : true,
        };
    };

    useEffect(() => {
        const token = TokenService.getLocalAccessToken();
        const rolesData = token ? (jwt(token)?.role ?? []) : [];
        const accesosData = token
            ? (
                jwt(token)?.Accesos 
                    ? Array.isArray(jwt(token)?.Accesos)
                        ? (
                            jwt(token)?.Accesos?.map(a => {
                                return _getAcceso(a);
                            }) ?? []
                        ) 
                        : _getAcceso(jwt(token)?.Accesos)
                    : []
            )
            : [];
        setRoles((Array.isArray(rolesData) ? [...rolesData].sort() : [rolesData]) ?? []);
        setAccesos((Array.isArray(accesosData) ? [...accesosData].sort() : [accesosData]) ?? []);
        setIsLoading(false);
    }, []);

    const getNombreRol = useCallback((rol) => {
        switch (rol) {
            case "Administrador":
                return strings.modules.administrador;
            case "Administrador cumplimiento":
                return strings.modules.administradorCumplimiento;
            case "Administrador oficios":
                return strings.modules.administradorOficios;
            case "Administrador riesgos":
                return strings.modules.administradorRiesgos;
            case "Administrador auditoría":
                return strings.modules.administradorAuditoria;
            case "Ejecutor":
                return strings.modules.ejecutor;
            case "Visador":
                return strings.modules.visador;
            case "Gerente":
                return strings.modules.gerente;
            case "Dueño de proceso":
                return strings.modules.duenoProceso;
            case "Representante legal":
                return strings.modules.representanteLegal;
            case "Lector":
                return strings.modules.lector;
            case "Revisor":
                return strings.modules.revisor;
            default:
                return rol;
        }
    }, [strings.modules.administrador, strings.modules.administradorAuditoria, strings.modules.administradorCumplimiento, strings.modules.administradorOficios, strings.modules.administradorRiesgos, strings.modules.duenoProceso, strings.modules.ejecutor, strings.modules.gerente, strings.modules.lector, strings.modules.representanteLegal, strings.modules.revisor, strings.modules.visador])


    const getNombreAcceso = useCallback((acceso) => {
        switch (acceso) {
            case "Documentos":
                return strings.menus.documentos;
            case "Compromisos":
                return strings.menus.compromisos;
            case "Mapa de calor":
                return strings.menus.mapaCalor;
            case "Activos de información":
                return strings.menus.activosInformacion;
            case "Riesgos":
                return strings.menus.riesgos;
            case "Actividades":
                return strings.menus.actividades;
            case "Mis actividades":
                return strings.menus.misActividades;
            case "Calendario":
                return strings.menus.calendario;
            case "Informes":
                return strings.menus.informes;
            case "Configuraciones":
                return strings.menus.configuraciones;
            case "Indicadores":
                return strings.menus.indicadores;
            case "Mis Indicadores":
                return strings.menus.misIndicadores;
            case "Oficios":
                return strings.menus.oficios;
            case "Asignador de oficios":
                return strings.menus.asignadorOficios;
            case "Mis oficios":
                return strings.menus.misOficios;
            case "TimeReport":
                return strings.menus.timeReport;
            case "TimeReport Mis reportes":
                return strings.menus.timeReportMisReportes;
            default:
                return acceso;
        };
    }, [strings.menus.actividades, strings.menus.activosInformacion, strings.menus.asignadorOficios, strings.menus.calendario, strings.menus.compromisos, strings.menus.configuraciones, strings.menus.documentos, strings.menus.indicadores, strings.menus.informes, strings.menus.mapaCalor, strings.menus.misActividades, strings.menus.misIndicadores, strings.menus.misOficios, strings.menus.oficios, strings.menus.riesgos, strings.menus.timeReport, strings.menus.timeReportMisReportes]);

    const esAccesoWrite = useCallback((acceso) => {
        return accesos.some(a => a.name === acceso && a.write) ?? false;
    }, [accesos]);

    return [roles, accesos, isLoading, getNombreRol, getNombreAcceso, esAccesoWrite];
}

export default useRoles;