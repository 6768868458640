import React from "react"
import { LocalizationContext } from "./LocalizationProvider"

export const useLocalization = () => {
    const { localization: strings, setLocalization } = React.useContext(LocalizationContext);
    //const strings = context.localization;

    /*const changeLocalization = useCallback((localization) => {
        context.setLocalization(localization);
    }, [context]);*/

    return { strings, setLocalization };
}