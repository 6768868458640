import React from "react";
import { useStateValue } from "../../contexto/store";
import { Redirect, Route } from "react-router-dom";


const NotFound = () => {
  const [{ sesionUsuario }] = useStateValue();

  return (sesionUsuario && sesionUsuario.autenticado === true)
      ? (<Redirect to="/"/>)
      : (<Redirect to="/auth/login"/>);
}

function RutaDefault() {
  return (<Route component={NotFound} />);
}

export default RutaDefault;
