import React from 'react';
import { CircularProgress, Container, Grid, Typography } from '@material-ui/core';
import ErrorTwoToneIcon from '@material-ui/icons/ErrorTwoTone';
// Hooks
import { useLocalization } from 'contexto/localization/useLocalization';


const LoadingOrError = ({ isLoading, hasError, errorCode }) => {
    // Idioma
    const { strings } = useLocalization();

    
    return (
        <>
            { isLoading && (
                <Container maxWidth="lg" style={{ paddingTop: 30, paddingBottom: 30 }}>
                    <Grid container>
                        <Grid item xs={12} md={12} container align="center" justifyContent="center">
                            <CircularProgress />
                        </Grid>
                    </Grid>
                </Container>
            )}
            { !isLoading && hasError && errorCode !== 404 && (
                <Container maxWidth="lg" justify="center" style={{ paddingTop: "50px", paddingBottom: "50px" }}>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} md={12} style={{ paddingTop: 30, paddingBottom: 30, textAlign: "center" }}>
                            <ErrorTwoToneIcon fontSize='large' style={{ color: "#ff6519", fontSize: "4rem" }} />
                            <Typography variant='body2'>{strings.components.sistema.ocurrioErrorRecInfo}</Typography>
                        </Grid>
                    </Grid>
                </Container>
            )}
            { !isLoading && hasError && errorCode === 404 && (
                <Container maxWidth="lg" justify="center" style={{ paddingTop: "50px", paddingBottom: "50px" }}>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} md={12} style={{ paddingTop: 30, paddingBottom: 30, textAlign: "center" }}>
                            <ErrorTwoToneIcon fontSize='large' style={{ color: "#FFA319", fontSize: "4rem" }} />
                            <Typography variant='body2'>{strings.components.sistema.elementoNoEncontrado}</Typography>
                        </Grid>
                    </Grid>
                </Container>
            )}
        </>
    );
};

export default LoadingOrError;