import HttpCliente from "servicios/HttpCliente";
import axios from "axios";
import getResponseError from "utils/getResponseError";

//FUNCION PARA NO PEDIR TOKEN DE SEGURIDAD EN LA PETICION
const instancia = axios.create();
instancia.CancelToken = axios.CancelToken;
instancia.isCancel = axios.isCancel;

export const ConfigModel = {
    TipoConfiguracion: '',
    Nombre: '',
    Valor: '',
};

/**
 * GET method - Obtiene lista de configuraciones por nombre de tipo
 * @param {string} tipoConfig Tipo de configuración
 * @return {Promise} Promesa de obtención
 */
export const obtenerConfigPersonalPorTipo = (tipoConfig) => {
    return new Promise((resolve, eject) => {
      HttpCliente.get("/ConfiguracionPersonal/tipo/" + tipoConfig)
        .then((response) => {
          resolve([response, undefined, response.status]);
        })
        .catch((error) => {
          const err = getResponseError(error);
          resolve([undefined, err, error.response?.status]);
        });
    });
  };

/**
 * GET method - Obtiene una configuración por tipo y nombre de configuración
 * @param {string} tipoConfig Tipo de configuración
 * @param {string} nombreConfig Nombre de configuración
 * @return {Promise} Promesa de obtención
 */
export const obtenerConfigPersonal = (tipoConfig, nombreConfig) => {
    return new Promise((resolve, eject) => {
      HttpCliente.get(`/ConfiguracionPersonal/tipo/${tipoConfig}/nombre/${nombreConfig}`)
        .then((response) => {
          resolve([response, undefined, response.status]);
        })
        .catch((error) => {
          const err = getResponseError(error);
          resolve([undefined, err, error.response?.status]);
        });
    });
  };

/**
 * PUT method - Actualiza las configuraciones
 * @param {{configuraciones: Array<ConfigModel>}} configuraciones configuraciones
 * @return {Promise} Promesa de actualización
 */
export const actualizarConfigPersonal = (configuraciones) => {
  return new Promise((resolve, eject) => {
    HttpCliente.put("/ConfiguracionPersonal", configuraciones)
      .then((response) => {
        resolve([response, undefined, response.status]);
      })
      .catch(error => {
        const err = getResponseError(error);
        resolve([undefined, err, error.response?.status]);
      });
  });
};