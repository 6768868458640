import HttpCliente from "servicios/HttpCliente";
import axios from "axios";
import getResponseError from "utils/getResponseError";

//FUNCION PARA NO PEDIR TOKEN DE SEGURIDAD EN LA PETICION
const instancia = axios.create();
instancia.CancelToken = axios.CancelToken;
instancia.isCancel = axios.isCancel;

export const ConfigModel = {
    TipoConfiguracion: '',
    Nombre: '',
    Valor: '',
};

/**
 * GET method - Obtiene lista de configuraciones por nombre de tipo
 * @param {string} tipoConfig Tipo de configuración
 * @return {Promise} Promesa de obtención
 */
export const obtenerConfigPorTipo = (tipoConfig) => {
    return new Promise((resolve, eject) => {
      HttpCliente.get("/Configuracion/tipo/" + tipoConfig)
        .then((response) => {
          resolve([response, undefined, response.status]);
        })
        .catch((error) => {
          const err = getResponseError(error);
          resolve([undefined, err, error.response?.status]);
        });
    });
  };

/**
 * GET method - Obtiene una configuración por tipo y nombre de configuración
 * @param {string} tipoConfig Tipo de configuración
 * @param {string} nombreConfig Nombre de configuración
 * @return {Promise} Promesa de obtención
 */
export const obtenerConfig = (tipoConfig, nombreConfig) => {
    return new Promise((resolve, eject) => {
      HttpCliente.get(`/Configuracion/tipo/${tipoConfig}/nombre/${nombreConfig}`)
        .then((response) => {
          resolve([response, undefined, response.status]);
        })
        .catch((error) => {
          const err = getResponseError(error);
          resolve([undefined, err, error.response?.status]);
        });
    });
  };

/**
 * PUT method - Actualiza las configuraciones
 * @param {{configuraciones: Array<ConfigModel>}} configuraciones configuraciones
 * @return {Promise} Promesa de actualización
 */
export const actualizarConfig = (configuraciones) => {
  return new Promise((resolve, eject) => {
    HttpCliente.put("/Configuracion", configuraciones)
      .then((response) => {
        resolve([response, undefined, response.status]);
      })
      .catch(error => {
        const err = getResponseError(error);
        resolve([undefined, err, error.response?.status]);
      });
  });
};

/**
 * POST method - Comprueba funcionamiento de credenciales SharePoint
 * @param {{ urlSitio: string, usuario: string, password: string, libreria: string }} credenciales credenciales
 * @return {Promise} Promesa de comprobación
 */
export const comprobarSharepoint = (credenciales) => {
  return new Promise((resolve, eject) => {
    HttpCliente.post("/Configuracion/sharepoint/checkConnection", credenciales)
      .then((response) => {
        resolve([response, undefined, response.status]);
      })
      .catch(error => {
        const err = getResponseError(error);
        resolve([undefined, err, error.response?.status]);
      });
  });
};