import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
//import toast from 'react-hot-toast';
import TokenService from './TokenService';

//axios.defaults.baseURL = 'https://localhost:5001/api';
//axios.defaults.baseURL = 'https://api-grc.austranet.com/api';
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.defaults.params = {
    culture : window.localStorage.getItem('idioma') ?? "es",
};

axios.interceptors.request.use(
    (config) => {
        const token = TokenService.getLocalAccessToken();
        if(token){
            config.headers.Authorization = `Bearer ` + token;
            return config;
        }
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Function that will be called to refresh authorization
const refreshAuthLogic = (failedRequest) =>
    axios.post('/login/refreshToken', {
        grantType: TokenService.getLocalRefreshToken(),
    }).then((tokenRefreshResponse) => {
        TokenService.setLocalTokens(tokenRefreshResponse.data.token, tokenRefreshResponse.data.refreshToken);
        failedRequest.response.config.headers['Authorization'] = 'Bearer ' + tokenRefreshResponse.data.token;
        return Promise.resolve();
    }).catch(() => {
        TokenService.removeTokens();
        window?.location?.reload();
    });

// Instantiate the interceptor
createAuthRefreshInterceptor(axios, refreshAuthLogic);


/*axios.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        const originalConfig = err.config;
        // Si refresh token expiró
        if (originalConfig.url === "/login/refreshToken" && err.response.status === 404) {
            toast((t) => (
                <span style={{ padding: "0px 0px 0px 20px" }}>
                  La sesión ha expirada
                  <button style={{ marginLeft: 15, padding: "15px 20px", backgroundColor: "#E64848", color: "#fff", border: "none", borderLeft: "1px solid", fontWeight: "bold", cursor: "pointer" }} onClick={() => window?.location?.reload()}>
                    Actualizar
                  </button>
                </span>
              ), {
                style: {
                    background: '#E64848',
                    color: '#fff',
                }});
        } else if (originalConfig.url !== "/login/login") {
            if (err.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;
                try {
                    const rs = await axios.post("/login/refreshToken", {
                        grantType: TokenService.getLocalRefreshToken(),
                    });
                    if (rs && rs.status === 200) {
                        TokenService.setLocalTokens(rs.data.token, rs.data.refreshToken);
                        return axios(originalConfig);
                    }
                } catch (e) {
                    return Promise.reject(e);
                }
            }
        }
        return Promise.reject(err);
    }
)*/


const requestGenerico = {
    get : (url, config = undefined) => axios.get(url, config),
    post : (url, body, config = undefined) => axios.post(url, body, config),
    put : (url, body, config = undefined) => axios.put(url, body, config),
    delete: (url, config = undefined) => axios.delete(url, config),
};

export default requestGenerico;