import React, { useState } from "react";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
// Hooks
import { useLocalization } from 'contexto/localization/useLocalization';


export const DeleteDialog = ({ message, isOpen, onOk, onCancel }) => {
    // Idioma
    const { strings } = useLocalization();
    // Estados
    const [isLoading, setIsLoading] = useState(false);

    // Funciones
    const _onOk = () => {
        setIsLoading(true);
        if (onOk) onOk();
    };


    // Vistas
    return (
        <Dialog
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
            open={isOpen}
            onClose={(event, reason) => {
                if (reason === "backdropClick") {
                    return false;
                }

                if (reason === "escapeKeyDown") {
                    return false;
                }
            }}
        >
            <DialogTitle id="confirmation-dialog-title">{strings.components.sistema.eliminar}</DialogTitle>
            <DialogContent dividers>
                {message}
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={onCancel} color="primary">
                    {strings.components.sistema.cancelar}
                </Button>
                <Button onClick={_onOk} color="secondary" disabled={isLoading}>
                    {strings.components.sistema.eliminar}
                </Button>
            </DialogActions>
        </Dialog>
    );
};