import './App.css';
//librerias de react
import React, { useState, useEffect, lazy, Suspense } from "react";
//libreria para redireccionamiento de componentes (props)  
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
//tema de la aplicacion
import { ThemeProvider } from '@material-ui/core/styles';
import theme from "./theme/theme";
//componentes de la libreria material ui
import { Grid, CssBaseline, LinearProgress, useMediaQuery, useTheme } from '@material-ui/core';
//Componente de seguridad de perfiles
import RutaSegura from 'componentes/navegacion/RutaSegura';
//Componente de navegacion
import AppNavbar from 'componentes/navegacion/AppNavbar';
import { useStateValue } from 'contexto/store';
import { obtenerUsuarioActual } from 'actions/UsuarioAction';
import RutaDefault from 'componentes/navegacion/RutaDefault';
// Servicios
import { obtenerConfig } from 'actions/ConfigAction';
// Hooks
import { useLocalization } from 'contexto/localization/useLocalization';
import logoAusGrc from './logoAusGrc.png';
import TokenService from 'servicios/TokenService';
import { obtenerConfigPersonal } from 'actions/ConfigPersonalAction';
import CookieConsent from 'react-cookie-consent';
import mergeObjects from 'utils/mergeObjects';
//import localizationEn from './config/localization/en/core';

//Vista de Usuarios
const Login = lazy(() => import('vistas/seguridad/Login'));
const AjustesPerfilUsuario = lazy(() => import('vistas/perfil/ajustes'));
const PaginadorUsuario = lazy(() => import('vistas/configuraciones/usuarios/usuarios/PaginadorUsuario'));
//Vistas de Documentos
const PaginadorDocumentos = lazy(() => import('vistas/documentos/paginador'));
const VerDocumento = lazy(() => import('vistas/documentos/VerDocumento'));
const PaginadorSolicitudDocumentos = lazy(() => import('vistas/documentos/solicitudes/paginador'));
const PaginadorIndicadoresDocumentos = lazy(() => import('vistas/documentos/indicadores/paginador'));
const PaginadorHallazgosDocumentos = lazy(() => import('vistas/documentos/hallazgos/paginador'));
const PaginadorPlanesAccionHallazgosDoc = lazy(() => import('vistas/documentos/planesAcciones/paginador'));
const PaginadorMisIndicadoresDoc = lazy(() => import('vistas/documentos/misIndicadores/paginador'))
//Vistas de Oficios
const PaginadorOficios = lazy(() => import('vistas/oficios/paginador'));
const VerOficio = lazy(() => import('vistas/oficios/VerOficio'));
const PaginadorSolicitudesCodOficios = lazy(() => import('vistas/solicitudesCodigosOficios/PaginadorSolicitudesCodOficios'));
const PaginadorReasignacionesOficios = lazy(() => import('vistas/reasignacionesOficio/PaginadorReasignacionesOficio'));
//Vistas de Compromisos
const PaginadorCompromisos = lazy(() => import('vistas/compromisos/paginador'));
const MapaDeCalorRiesgo = lazy(() => import('vistas/procesos/mapaCalor/MapaDeCalorRiesgo'));
const RiesgoTiempoReal = lazy(() => import('vistas/procesos/riesgoTiempoReal/RiesgoTiempoReal'));
//Vistas de procesos
const PaginadorProcesos = lazy(() => import('vistas/procesos/PaginadorProcesos'));
//Vista mapa de calor
const MapaDeCalor = lazy(() => import('vistas/mapaDeCalor/MapaDeCalor'));
//Vistas de Riesgos
const PaginadorRiesgos = lazy(() => import('vistas/riesgos/PaginadorRiesgos'));
const PaginadorMisRiesgos = lazy(() => import('vistas/riesgos/PaginadorMisRiesgos'));
//Vistas de eventos de Riesgos
const PaginadorEventoRiesgos = lazy(() => import('vistas/eventosRiesgos/paginador'));
//Vista de Actividades
const PaginadorActividades = lazy(() => import('vistas/actividades/paginador'));
const DetalleActividad = lazy(() => import('vistas/actividades/detalle'));
//Vista de Mis actividades
const PaginadorMisActividades = lazy(() => import('vistas/misActividades/paginador'));
//Vista de controles
const PaginadorControles = lazy(() => import('vistas/controles/paginador'));
//Vista planes de acción
const PaginadorPlanesAccion = lazy(() => import('vistas/planesAccion/paginador'));
//Vista de Actividades oficios
const PaginadorActividadesOficios = lazy(() => import('vistas/asignadorOficios/PaginadorActividadesOficios'));
const VerActividadOficio = lazy(() => import('vistas/asignadorOficios/VerActividadOficio'));
//Vista de Mis actividades oficios
const PaginadorMisActividadesOficios = lazy(() => import('vistas/misOficios/PaginadorMisActividadesOficio'));
//Vista de Solicitudes Prorroga de oficios
const PaginadorProrrogasOficio = lazy(() => import('vistas/prorroga/oficio/PaginadorSolicitudProrrogaOficio'));
// Activos de información
const PaginadorActivos = lazy(() => import('vistas/activosInformacion/paginador'));
//Vista de TimeReport
const Marcacion = lazy(() => import('vistas/timeReport/misReportes/Marcacion'));
const MisReportes = lazy(() => import('vistas/timeReport/misReportes/PaginadorMisReportes'));
//Vista de Configuraciones
const Configuraciones = lazy(() => import('vistas/configuraciones/Configuraciones'));
const ConfigSistema = lazy(() => import('vistas/configuraciones/ConfigSistema'));
const ConfigCumplimiento = lazy(() => import('vistas/configuraciones/ConfigCumplimiento'));
const ConfigRiesgos = lazy(() => import('vistas/configuraciones/ConfigRiesgos'));
const ConfigActivosInf = lazy(() => import('vistas/configuraciones/ConfigActivosInf'));
const ConfigOficios = lazy(() => import('vistas/configuraciones/ConfigOficios'));
const ConfigAuditorias = lazy(() => import('vistas/configuraciones/ConfigAuditorias'));
const ConfigTimeReport = lazy(() => import('vistas/configuraciones/ConfigTimeReport'));
const ConfigAssessment = lazy(() => import('vistas/configuraciones/ConfigAssessment'));
const MatrizValorizacion = lazy(() => import('vistas/configuraciones/visualizadores/matrizValorizacion/MatrizValorizacion'));
const PaginadorAreas = lazy(() => import('vistas/configuraciones/documentos/areas/PaginadorAreas'));
const PaginadorTipoDocumentos = lazy(() => import('vistas/configuraciones/documentos/tipoDocumento/PaginadorTipoDocumentos'));
const PaginadorClasificacionDocumentos = lazy(() => import('vistas/configuraciones/documentos/clasificacionDocumentos/PaginadorClasificacionDocumentos'));
const PaginadorOrganismoEmisores = lazy(() => import('vistas/configuraciones/documentos/organismosEmisores/PaginadorOrganismoEmisores'));
const PaginadorEstadoDocumentos = lazy(() => import('vistas/configuraciones/documentos/estadoDocumento/PaginadorEstadoDocumentos'));
const PaginadorCanalEnvio = lazy(() => import('vistas/configuraciones/oficios/canalesEnvio/PaginadorCanalEnvio'));
const PaginadorCanalRecepcion = lazy(() => import('vistas/configuraciones/oficios/canalesRecepcion/PaginadorCanalRecepcion'));
const PaginadorOficinas = lazy(() => import('vistas/configuraciones/oficios/oficinas/PaginadorOficinas'));
const PaginadorCargos = lazy(() => import('vistas/configuraciones/oficios/cargos/PaginadorCargos'));
const PaginadorIdentificadoresTipoCausa = lazy(() => import('vistas/configuraciones/oficios/identificadoresTipoCausa/PaginadorIdentificadoresTipoCausa'));
const PaginadorTiposRequerimientos = lazy(() => import('vistas/configuraciones/oficios/tiposRequerimiento/PaginadorTiposRequerimientos'));
const PaginadorTipoOficios = lazy(() => import('vistas/configuraciones/oficios/tipoOficio/PaginadorTipoOficios'));
const PaginadorMaterias = lazy(() => import('vistas/configuraciones/oficios/materias/PaginadorMaterias'));
const PaginadorEstadoOficio = lazy(() => import('vistas/configuraciones/oficios/estadoOficio/PaginadorEstadoOficio'));
const PaginadorDelitos = lazy(() => import('vistas/configuraciones/oficios/delitos/PaginadorDelitos'));
const PaginadorRelacionUsuarioMateria = lazy(() => import('vistas/configuraciones/oficios/relacionUsuarioMateria/PaginadorRelacionUsuarioMateria'));
const PaginadorRelacionUsuarioMateriaGerencia = lazy(() => import('vistas/configuraciones/oficios/relacionUsuarioMateriaGerencia/PaginadorRelacionUsuarioMateriaGerencia'));
const PaginadorProcesoNivel0 = lazy(() => import('vistas/configuraciones/compromisos/procesoNivel0/PaginadorProcesoNivel0'));
const PaginadorProcesoNivel1 = lazy(() => import('vistas/configuraciones/compromisos/procesoNivel1/PaginadorProcesoNivel1'));
const PaginadorProcesoNivel2 = lazy(() => import('vistas/configuraciones/compromisos/procesoNivel2/PaginadorProcesoNivel2'));
const PaginadorProcesoNivel3 = lazy(() => import('vistas/configuraciones/compromisos/procesoNivel3/PaginadorProcesoNivel3'));
const PaginadorProcesoNivel4 = lazy(() => import('vistas/configuraciones/compromisos/procesoNivel4/PaginadorProcesoNivel4'));
const PaginadorConsecuencias = lazy(() => import('vistas/configuraciones/actividades/consecuencias/PaginadorConsecuencias'));
const PaginadorTipoAtrasos = lazy(() => import('vistas/configuraciones/actividades/tiposAtrasos/PaginadorTipoAtrasos'));
const CierreMasivoActividades = lazy(() => import('vistas/configuraciones/actividades/cierreMasivo/CierreMasivaActividades'));
const ReasignacionMasivaActividades = lazy(() => import('vistas/configuraciones/actividades/reasignacionMasiva/ReasignacionMasivaActividades'));
const PaginadorFeriados = lazy(() => import('vistas/configuraciones/feriados/feriados/PaginadorFeriados'));
const PaginadorEmpresas = lazy(() => import('vistas/configuraciones/empresas/empresas/PaginadorEmpresas'));
const PaginadorGerencias = lazy(() => import('vistas/configuraciones/empresas/gerencias/PaginadorGerencias'));
const PaginadorAreaEmpresas = lazy(() => import('vistas/configuraciones/empresas/areas/PaginadorAreaEmpresas'));
const PaginadorRelacionGerencia = lazy(() => import('vistas/configuraciones/empresas/relacionGerencias/PaginadorRelacionGerencia'));
const PaginadorTipoActivos = lazy(() => import('vistas/configuraciones/activosInformacion/tipoActivos/PaginadorTipoActivos'));
const PaginadorFormatoActivos = lazy(() => import('vistas/configuraciones/activosInformacion/formatoActivos/PaginadorFormatoActivos'));
const PaginadorUbicacionActivos = lazy(() => import('vistas/configuraciones/activosInformacion/ubicaciones/PaginadorUbicacionActivos'));
const PaginadorTipoRiesgos = lazy(() => import('vistas/configuraciones/riesgos/tipoRiesgo/PaginadorTipoRiesgos'));
const PaginadorSubTipoRiesgos = lazy(() => import('vistas/configuraciones/riesgos/subTipoRiesgo/PaginadorSubTipoRiesgos'));
const PaginadorFactorIndice = lazy(() => import('vistas/configuraciones/riesgos/factoresIndices/PaginadorFactorIndice'));
const PaginadorPlantillaControles = lazy(() => import('vistas/configuraciones/riesgos/plantilla/PaginadorPlantillaControles'));
const PaginadorRoles = lazy(() => import('vistas/configuraciones/usuarios/roles/PaginadorRoles'));
const ConfigBiblioteca = lazy(() => import('vistas/configuraciones/generales/biblioteca/ConfigBibliotecas'));
const ConfigGeneral = lazy(() => import('vistas/configuraciones/generales/general/ConfigGeneral'));
const ConfigNotificaciones = lazy(() => import('vistas/configuraciones/generales/notificaciones/ConfigNotificaciones'));
const PaginadorPerfilEnvioCorreo = lazy(() => import('vistas/configuraciones/perfilesEnvioCorreo/PaginadorPerfilEnvioCorreos'));
const ImportadorDocComp = lazy(() => import('vistas/configuraciones/importacion/documentosYcompromisos/ImportadorDocComp'));
const ImportadorOficiosOpt = lazy(() => import('vistas/configuraciones/importacion/oficios/ImportadorOficiosOpt'));
const ImportadorAsignacionOficios = lazy(() => import('vistas/configuraciones/importacion/oficios/AsignacionOficios'));
const ImportadorProcesoRiesgos = lazy(() => import('vistas/configuraciones/importacion/procesoRiesgos/ImportadorProcesoRiesgos'));
const ImportadorIndicadores = lazy(() => import('vistas/configuraciones/importacion/indicadores/ImportadorIndicadores'));
const ImportadorEventosRiesgos = lazy(() => import('vistas/configuraciones/importacion/eventosRiesgo/ImportadorEventosRiesgos'));
const ImportadorPlanesAccion = lazy(() => import('vistas/configuraciones/importacion/planesAccion/ImportadorPlanesAccion'));
const ImportadorOrganigrama = lazy(() => import('vistas/configuraciones/importacion/organigrama/ImportadorOrganigrama'));
const ImportadorCumplimientos = lazy(() => import('vistas/configuraciones/importacion/cumplimiento/ImportadorCumplimiento'));
const ImportadorIndicadoresCumpl = lazy(() => import('vistas/configuraciones/importacion/cumplimiento/indicadores/ImportadorIndicadoresCumpl'));
const RebajaMasivaOficios = lazy(() => import('vistas/configuraciones/importacion/oficios/RebajaOficios'));
const ReasignacionMasivaOficios = lazy(() => import('vistas/configuraciones/oficios/reasignacionMasiva/ReasignacionMasivaOficios'));
const AsignacionMasivaPorTemaOficios = lazy(() => import('vistas/configuraciones/oficios/asignacionMasivaPorTema/AsignacionMasivaPorTemaOficios'));
const PaginadorRegiones = lazy(() => import('vistas/configuraciones/regiones/regiones/PaginadorRegiones'));
const PaginadorHistorialEnviosCorreos = lazy(() => import('vistas/configuraciones/registros/historialCorreos/PaginadorHistorialEnviosCorreos'));
const PaginadorLogsOficiosAuto = lazy(() => import('vistas/configuraciones/registros/logsOficios/PaginadorLogsOficiosAuto'));
const PaginadorAsisClientes = lazy(() => import('vistas/configuraciones/timeReport/clientes/PaginadorClientes'));
const PaginadorAsisEtapas = lazy(() => import('vistas/configuraciones/timeReport/etapas/PaginadorEtapas'));
const PaginadorAsisProyectos = lazy(() => import('vistas/configuraciones/timeReport/proyectos/PaginadorProyectos'));
const PaginadorAsisTiposProyecto = lazy(() => import('vistas/configuraciones/timeReport/tiposProyectos/PaginadorTipoProyecto'));
const PaginadorAsisRelacionEmpresaUsuario = lazy(() => import('vistas/configuraciones/timeReport/relacionEmpresaUsuario/PaginadorAsisRelacionEmpresaUsuario'));
const PaginadorAsisHorarios = lazy(() => import('vistas/configuraciones/timeReport/horarios/PaginadorHorarios'));
const PaginadorAudPlantillas = lazy(() => import('vistas/configuraciones/auditoria/plantillas/PaginadorPlantillas'));
const PaginadorAdtCiclos = lazy(() => import('vistas/configuraciones/auditoria2/ciclos/PaginadorCiclosAuditoria'));
const PaginadorAdtAreas = lazy(() => import('vistas/configuraciones/auditoria2/areas/PaginadorAreasAuditoria'));
const PaginadorAdtCriticidadesHallazgos = lazy(() => import('vistas/configuraciones/auditoria2/criticidadesHallazgo/PaginadorCriticidadesHallallazgosAdt'));
const PaginadorAdtTiposTrabajoAuditoria = lazy(() => import('vistas/configuraciones/auditoria2/tipoTrabajos/PaginadorTipoTrabajosAuditoria'));
const PaginadorAdtTiposAuditoria = lazy(() => import('vistas/configuraciones/auditoria2/tiposAuditorias/PaginadorTipoAuditorias'));
const ArbolOrganigrama = lazy(() => import('vistas/configuraciones/organigrama/organigrama/ArbolOrganigrama'));
const PaginadorOrgCargos = lazy(() => import('vistas/configuraciones/organigrama/cargos/PaginadorOrgCargos'));
const PaginadorPlantillasEvaluaciones = lazy(() => import('vistas/configuraciones/assessment/plantillasEvaluaciones/paginador'));
const PaginadorEmisorHallazgos = lazy(() => import('vistas/configuraciones/documentos/emisoresHallazgos/PaginadorEmisorHallazgos'));
const PaginadorOrigenHallazgos = lazy(() => import('vistas/configuraciones/documentos/origenesHallazgos/PaginadorOrigenHallazgos'));
const PaginadorTipoHallazgos = lazy(() => import('vistas/configuraciones/documentos/tiposHallazgos/PaginadorTipoHallazgos'));
//Estados eventos riesgo
const PaginadorEstadosEventoRiesgos = lazy(() => import('vistas/configuraciones/riesgos/estadosEventosRiesgo/PaginadorEstadoEventosRiesgo'));
const PaginadorEstadosPlanesAccion = lazy(() => import('vistas/configuraciones/riesgos/estadosPlanAccion/PaginadorEstadoPlanAccion'));
// Indicadores
const PaginadorIndicadores = lazy(() => import('vistas/indicadores/PaginadorIndicadores'));
const PaginadorMisIndicadores = lazy(() => import('vistas/misIndicadores/PaginadorMisIndicadores'));
const VerIndicador = lazy(() => import('vistas/indicadores/VerIndicador'));
// Tareas Cortas - Deshabilitado
// const PaginadorTareasCortas = lazy(() => import('vistas/tareasCortas/PaginadorTareasCortas'));
// const VerTareaCorta = lazy(() => import('vistas/tareasCortas/VerTareaCorta'));
// //Vista de Mis Tareas cortas - Deshabilitado
// const PaginadorMisTareasCortas = lazy(() => import('vistas/misTareasCortas/PaginadorMisTareasCortas'));
// Auditoria
const PaginadorAudProyectos = lazy(() => import('vistas/auditoria/PaginadorProyectos'));
const PaginadorAudMisActividades = lazy(() => import('vistas/misActividadesAuditoria/PaginadorAudMisActividades'));
// Auditoria V2
const PaginadorAdtPlanAuditoria = lazy(() => import('vistas/auditoria2/planesAuditoria/paginador'));
const PaginadorAdtAuditoria = lazy(() => import('vistas/auditoria2/auditorias/paginador'));
const PaginadorAdtMisAuditoria = lazy(() => import('vistas/auditoria2/misAuditorias/paginador'));
const PaginadorMisPruebasAuditoria = lazy(() => import('vistas/auditoria2/misAuditorias/pruebas/paginador'));
const PaginadorMisHallazgosAuditoria = lazy(() => import('vistas/auditoria2/misAuditorias/hallazgos/paginador'));
const PaginadorMisRecomendaciones = lazy(() => import('vistas/auditoria2/misRecomendaciones/PaginadorMisRecomendaciones'));
//const PaginadorMisCompromisos = lazy(() => import('vistas/auditoria2/misCompromisos/PaginadorMisCompromisos'));
const PaginadorMisCompromisos = lazy(() => import('vistas/auditoria2/misCompromisos/paginador'));
const PaginadorMisHorasAuditoria = lazy(() => import('vistas/auditoria2/misHorasAuditorias'));
const PaginadorMisSolicitudesProrroga = lazy(() => import('vistas/auditoria2/misProrrogas/paginador'));
const ImportadorAuditorias = lazy(() => import('vistas/configuraciones/auditoria2/importacion/ImportadorAuditorias'));
const ImportadorAdtActPreliminares = lazy(() => import('vistas/configuraciones/auditoria2/importacion/ImportadorActPreliminares'));
const ImportadorReglasAuditoria = lazy(() => import('vistas/configuraciones/auditoria2/importacion/ImportadorReglasAuditoria'));
const ActualizadorReglasAuditoria  = lazy(() => import('vistas/configuraciones/auditoria2/actualizacion/ActualizadorReglasAuditoria'));
const PaginadorReglasAuditoria = lazy(() => import('vistas/auditoria2/reglaAuditoria/paginador'));
const PaginadorMisReglasAuditoria = lazy(() => import('vistas/misReglasAuditoria/PaginadorMisReglasAuditoria'));
const PaginadorJustificacionesReglasAuditoria = lazy(() => import('vistas/auditoria2/reglaAuditoria/justificaciones/paginador'));
const PaginadorPlanesAccionReglas = lazy(() => import('vistas/auditoria2/reglaAuditoria/planesAcciones/paginador'));
// Assessments
const PaginadorEvaluaciones = lazy(() => import('vistas/assessments/paginador'));
const PaginadorMisEvaluaciones = lazy(() => import('vistas/assessments/misEvaluaciones/paginador'));
//Calendario
const Calendario = lazy(() => import('vistas/calendario/Calendario'));
// Reportes
const RepeticionesRechazadas = lazy(() => import('vistas/reportes/RepeticionesRechazadas'));
const ProximosVencimientos = lazy(() => import('vistas/reportes/ProximosVencimientos'));
const Vencidas = lazy(() => import('vistas/reportes/Vencidas'));
const ExportadorXls = lazy(() => import('vistas/reportes/ExportadorXls'));
const ReporteCumplimiento = lazy(() => import('vistas/reportes/ReporteCumplimiento'));
const ReporteDocumento = lazy(() => import('vistas/reportes/ReporteDocumento'));
const ReporteRiesgos = lazy(() => import('vistas/reportes/ReporteRiesgos'));
const ReporteIndicadores = lazy(() => import('vistas/reportes/ReporteIndicadores'));
const ReporteOficios = lazy(() => import('vistas/reportes/ReporteOficios'));
const ReporteControles = lazy(() => import('vistas/reportes/ReporteControles'));
const ReportePlanesAccion = lazy(() => import('vistas/reportes/ReportePlanesAccion'));
const AtrasosOficios = lazy(() => import('vistas/reportes/AtrasosOficios'));
const ReporteActivos = lazy(() => import('vistas/reportes/ReporteActivos'));
const ReporteEventosRiesgos = lazy(() => import('vistas/reportes/ReporteEventosRiesgos'));
const ReporteReglasAuditoria = lazy(() => import('vistas/reportes/ReporteReglasAuditoria'));
const ReporteAssessment = lazy(() => import('vistas/reportes/ReporteAssessments'));
//Vista principal
const Dashboard = lazy(() => import('vistas/dashboard/Dashboard'));
//Externos (Redireccionadores)
const RedireccionadorEmail = lazy(() => import('vistas/notificaciones/emails/RedireccionadorEmail'));


function App({ versionApp }) {
  const [, dispatch] = useStateValue();
  const [iniciaApp, setIniciaApp] = useState(false);
  const { setLocalization } = useLocalization();
  const them = useTheme();
  const isDesktop = useMediaQuery(them.breakpoints.up('md'));
  const [openMenu, setOpenMenu] = useState(true);

  useEffect(() => {
    setOpenMenu(isDesktop);
  }, [isDesktop]);

  useEffect(() => {
    const iniciarApp = async () => {
      // Obtener usuario actual
      const [res0, err0] = await obtenerUsuarioActual(dispatch);
      if (err0 && !res0) TokenService.removeTokens();

      // Obtener idioma
      const [res1] = await obtenerConfig("general", "general_idioma_default");
      const [res2] = await obtenerConfigPersonal("general", "general_idioma_default");
      const [res3] = await obtenerConfig("general", "general_idioma_core");
      const idiomaSystem = res1?.data?.valor;
      const idiomaUsuario = res2?.data?.valor;
      const idiomaCustom = res3?.data?.valor ? JSON.parse(res3?.data?.valor) : {};
      const idiomaNav = navigator.language;

      const idiomaSelecc = idiomaUsuario ? idiomaUsuario : (idiomaSystem ? idiomaSystem : (idiomaNav ? idiomaNav : "es"));

      window.localStorage.setItem('idioma', idiomaSelecc);
      switch (idiomaSelecc) {
        case "es":
          const idiomaES = await import('./config/localization/es/core');
          setLocalization(mergeObjects(idiomaES.default, idiomaCustom));
          break;
        case "en":
          const idiomaEN = await import('./config/localization/en/core');
          setLocalization(mergeObjects(idiomaEN.default, idiomaCustom));
          break;
        default:
          const idiomaDEF = await import('./config/localization/es/core');
          setLocalization(mergeObjects(idiomaDEF.default, idiomaCustom));
          break;
      }

      await new Promise(resolve => setTimeout(resolve, 500));
      setIniciaApp(true);
    };

    if (!iniciaApp) iniciarApp();
  }, [dispatch, iniciaApp, setLocalization]);

  return (
    <>
      {!iniciaApp &&
        <div className="center">
          <div>
            <img src={logoAusGrc} style={{ height: 100 }} alt="Logo" />
          </div>
          <div style={{ marginTop: 10, marginBottom: 10 }}>
            v{versionApp}
          </div>
          <div className="meter">
            <span styles={{ width: '100%' }}><span className="progress"></span></span>
          </div>
        </div>
      }
      {iniciaApp &&
        <ThemeProvider theme={theme}>
          <Router>
            <div style={{ display: isDesktop ? "flex" : "block" }}>
              <CssBaseline />
              <AppNavbar openMenu={openMenu} onChangeOpenMenu={(e) => setOpenMenu(e)} />
              <Suspense fallback={<LinearProgress style={{ color: "#04afde" }} />}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', padding: theme.spacing(0, 1) }} />
                <Grid container style={{ marginTop: 50 }}>
                  <Switch>
                    <RutaSegura
                      exact
                      path="/"
                      component={Dashboard}
                    />
                    <Route
                      exact
                      path="/auth/login"
                      component={Login}
                    />
                    <RutaSegura
                      exact
                      path="/notifications/email"
                      component={RedireccionadorEmail}
                    />
                    <RutaSegura
                      exact
                      path="/perfil/ajustes"
                      component={AjustesPerfilUsuario}
                    />
                    <RutaSegura
                      exact
                      path="/documentos/paginador"
                      permisos="DOC"
                      component={PaginadorDocumentos}
                    />
                    <RutaSegura
                      exact
                      path="/documentos/detalle/:documentoId"
                      component={VerDocumento}
                    />
                    <RutaSegura
                      exact
                      path="/documentos/solicitudes/paginador"
                      permisos="SDC"
                      roles={["Solicitante documento", "Pre-revisor documento" ,"Revisor documento", "Aprobador documento"]}
                      component={PaginadorSolicitudDocumentos}
                    />
                    <RutaSegura
                      exact
                      path="/documentos/indicadores/paginador"
                      permisos="IDO"
                      component={PaginadorIndicadoresDocumentos}
                    />
                    <RutaSegura
                      exact
                      path="/documentos/mis-indicadores/paginador"
                      roles={["Revisor"]}
                      component={PaginadorMisIndicadoresDoc}
                    />
                    <RutaSegura
                      exact
                      path="/documentos/hallazgos/paginador"
                      permisos="MHD"
                      component={PaginadorHallazgosDocumentos}
                    />
                    <RutaSegura
                      exact
                      path="/documentos/planes-acciones/paginador"
                      permisos="MPH"
                      component={PaginadorPlanesAccionHallazgosDoc}
                    />
                    <RutaSegura
                      exact
                      path="/oficios/paginador"
                      permisos="OFI"
                      component={PaginadorOficios}
                    />
                    <RutaSegura
                      exact
                      path="/oficios/detalle/:oficioId"
                      component={VerOficio}
                    />
                    <RutaSegura
                      exact
                      path="/oficios/solicitudesCodigos/paginador"
                      permisos="SCO"
                      component={PaginadorSolicitudesCodOficios}
                    />
                    <RutaSegura
                      exact
                      path="/oficios/solicitudesReasignacion/paginador"
                      permisos="ROF"
                      component={PaginadorReasignacionesOficios}
                    />
                    <RutaSegura
                      exact
                      path="/compromisos/paginador"
                      permisos="COM"
                      component={PaginadorCompromisos}
                    />
                    <RutaSegura
                      exact
                      path="/procesos/:idCompromiso/mapaDeCalor"
                      permisos="MAP"
                      component={MapaDeCalorRiesgo}
                    />
                    <RutaSegura
                      exact
                      path="/procesos/:idCompromiso/riesgosTiempoReal"
                      permisos="PRO"
                      component={RiesgoTiempoReal}
                    />
                    <RutaSegura
                      exact
                      path="/procesos/paginador"
                      permisos="PRO"
                      component={PaginadorProcesos}
                    />
                    <RutaSegura
                      exact
                      path="/mapaDeCalor"
                      permisos="MAP"
                      component={MapaDeCalor}
                    />
                    <RutaSegura
                      exact
                      path="/riesgos/paginador"
                      permisos="RIE"
                      component={PaginadorRiesgos}
                    />
                    <RutaSegura
                      exact
                      path="/riesgos/:idRiesgo"
                      permisos="RIE"
                      component={PaginadorRiesgos}
                    />
                    <RutaSegura
                      exact
                      path="/misriesgos/paginador"
                      permisos="MRI"
                      component={PaginadorMisRiesgos}
                    />
                    <RutaSegura
                      exact
                      path="/eventosRiesgos/paginador"
                      component={PaginadorEventoRiesgos}
                    />
                    <RutaSegura
                      exact
                      path="/activosInformacion/paginador"
                      permisos="ACI"
                      component={PaginadorActivos}
                    />
                    <RutaSegura
                      exact
                      path="/actividades/paginador"
                      permisos="ACT"
                      component={PaginadorActividades}
                    />
                    <RutaSegura
                      exact
                      path="/misactividades/paginador"
                      roles={["Visador", "Ejecutor", "Lector"]}
                      component={PaginadorMisActividades}
                    />
                    <RutaSegura
                      exact
                      path="/actividades/detalle/:actividadId"
                      permisos="AOF"
                      roles={["Visador", "Ejecutor"]}
                      component={DetalleActividad}
                    />
                    <RutaSegura
                      exact
                      path="/actividades-oficios/paginador"
                      permisos="AOF"
                      component={PaginadorActividadesOficios}
                    />
                    <RutaSegura
                      exact
                      path="/misactividades-oficios/paginador"
                      roles={["Visador Oficios", "Ejecutor Oficios", "Lector"]}
                      component={PaginadorMisActividadesOficios}
                    />
                    <RutaSegura
                      exact
                      path="/actividades-oficios/detalle/:actividadOficioId"
                      permisos="AOF"
                      roles={["Visador Oficios", "Ejecutor Oficios"]}
                      component={VerActividadOficio}
                    />
                    <RutaSegura
                      exact
                      path="/solicitud-prorroga/oficios"
                      permisos="OPR"
                      component={PaginadorProrrogasOficio}
                    />
                    <RutaSegura
                      exact
                      path="/controles/paginador"
                      permisos="CTR"
                      component={PaginadorControles}
                    />
                    <RutaSegura
                      exact
                      path="/planes-accion/paginador"
                      permisos="PLA"
                      component={PaginadorPlanesAccion}
                    />
                    <RutaSegura
                      exact
                      path="/miscontroles/paginador"
                      roles={["Visador", "Ejecutor", "Lector"]}
                      component={PaginadorMisActividades}
                    />
                    <RutaSegura
                      exact
                      path="/controles/detalle/:actividadId"
                      permisos="AOF"
                      roles={["Visador", "Ejecutor"]}
                      component={DetalleActividad}
                    />
                    <RutaSegura
                      exact
                      path="/indicadores/paginador"
                      permisos="IND"
                      component={PaginadorIndicadores}
                    />
                    <RutaSegura
                      exact
                      path="/indicadores/detalle/:indicadorId"
                      permisos="IND"
                      roles={["Revisor"]}
                      component={VerIndicador}
                    />
                    <RutaSegura
                      exact
                      path="/misindicadores/paginador"
                      roles={["Revisor"]}
                      component={PaginadorMisIndicadores}
                    />
                    {/* <RutaSegura
                      exact
                      path="/tareascortas/paginador"
                      permisos="AOF"
                      component={PaginadorTareasCortas}
                    />
                    <RutaSegura
                      exact
                      path="/mistareascortas/paginador"
                      roles={["Visador", "Ejecutor", "Lector"]}
                      component={PaginadorMisTareasCortas}
                    />
                    <RutaSegura
                      exact
                      path="/tareascortas/detalle/:tareaCortaId"
                      permisos="AOF"
                      roles={["Visador", "Ejecutor"]}
                      component={VerTareaCorta}
                    /> */}
                    <RutaSegura
                      exact
                      path="/timeReport/mis-reportes/marcacion"
                      permisos="ARE"
                      component={Marcacion}
                    />
                    <RutaSegura
                      exact
                      path="/timeReport/mis-reportes/paginador"
                      permisos="ARE"
                      component={MisReportes}
                    />
                    <RutaSegura
                      exact
                      path="/auditoria/proyectos/paginador"
                      permisos="AUD"
                      component={PaginadorAudProyectos}
                    />
                    <RutaSegura
                      exact
                      path="/auditoria/v2/planesAuditoria/paginador"
                      permisos="ADT"
                      component={PaginadorAdtPlanAuditoria}
                    />
                    <RutaSegura
                      exact
                      path="/auditoria/v2/auditorias/paginador"
                      permisos="ADT"
                      component={PaginadorAdtAuditoria}
                    />
                    <RutaSegura
                      exact
                      path="/auditoria/v2/misauditorias/paginador"
                      roles={["Auditor"]}
                      permisos="MAD"
                      component={PaginadorAdtMisAuditoria}
                    />
                    <RutaSegura
                      exact
                      path="/auditoria/v2/misauditorias/pruebas/paginador"
                      roles={["Auditor"]}
                      permisos="MPA"
                      component={PaginadorMisPruebasAuditoria}
                    />
                    <RutaSegura
                      exact
                      path="/auditoria/v2/misrecomendaciones/paginador"
                      roles={["Gerente", "Auditor"]}
                      permisos="MCA"
                      component={PaginadorMisRecomendaciones}
                    />
                    <RutaSegura
                      exact
                      path="/auditoria/v2/miscompromisos/paginador"
                      permisos="MCA"
                      component={PaginadorMisCompromisos}
                    />
                    <RutaSegura
                      exact
                      path="/auditoria/v2/mishorasauditorias/paginador"
                      permisos="MHR"
                      component={PaginadorMisHorasAuditoria}
                    />
                    <RutaSegura
                      exact
                      path="/auditoria/misactividades/paginador"
                      permisos="MAA"
                      component={PaginadorAudMisActividades}
                    />
                    <RutaSegura
                      exact
                      path="/auditoria/v2/reglasauditoria/paginador"
                      permisos="EAD"
                      component={PaginadorReglasAuditoria}
                    />
                    <RutaSegura
                      exact
                      path="/auditoria/v2/reglasauditoria/justificaciones/paginador"
                      roles={["Gerente"]}
                      permisos="EAD"
                      component={PaginadorJustificacionesReglasAuditoria}
                    />
                    <RutaSegura
                      exact
                      path="/auditoria/v2/reglasauditoria/planesAcciones/paginador"
                      permisos="MPR"
                      component={PaginadorPlanesAccionReglas}
                    />
                    <RutaSegura
                      exact
                      path="/auditoria/v2/misauditorias/hallazgos/paginador"
                      roles={["Auditor"]}
                      permisos="MPA"
                      component={PaginadorMisHallazgosAuditoria}
                    />
                    <RutaSegura
                      exact
                      path="/calendario"
                      permisos="CAL"
                      component={Calendario}
                    />
                    <RutaSegura
                      exact
                      path="/configuraciones"
                      permisos="CON"
                      component={Configuraciones}
                    />
                    <RutaSegura
                      exact
                      path="/configuraciones/gen/sistema"
                      permisos="CON"
                      component={ConfigSistema}
                    />
                    <RutaSegura
                      exact
                      path="/configuraciones/gen/cumplimiento"
                      permisos="CON"
                      component={ConfigCumplimiento}
                    />
                    <RutaSegura
                      exact
                      path="/configuraciones/gen/riesgos"
                      permisos="CON"
                      component={ConfigRiesgos}
                    />
                    <RutaSegura
                      exact
                      path="/configuraciones/gen/activos-inf"
                      permisos="CON"
                      component={ConfigActivosInf}
                    />
                    <RutaSegura
                      exact
                      path="/configuraciones/gen/oficios"
                      permisos="CON"
                      component={ConfigOficios}
                    />
                    <RutaSegura
                      exact
                      path="/configuraciones/gen/auditorias"
                      permisos="CON"
                      component={ConfigAuditorias}
                    />
                    <RutaSegura
                      exact
                      path="/configuraciones/gen/time-report"
                      permisos="CON"
                      component={ConfigTimeReport}
                    />
                    <RutaSegura
                      exact
                      path="/configuraciones/gen/assessment"
                      permisos="CON"
                      component={ConfigAssessment}
                    />

                    <RutaSegura
                      exact
                      path="/configuraciones/usuario/paginador"
                      permisos="CON"
                      component={PaginadorUsuario}
                    />
                    <RutaSegura
                      exact
                      path="/configuraciones/usuario/roles/paginador"
                      permisos="CON"
                      component={PaginadorRoles}
                    />
                    <RutaSegura
                      exact
                      path="/configuraciones/matrizValorizacion"
                      permisos="CON"
                      component={MatrizValorizacion}
                    />
                    <RutaSegura
                      exact
                      path="/configuraciones/documentos/areas/paginador"
                      permisos="CON"
                      component={PaginadorAreas}
                    />
                    <RutaSegura
                      exact
                      path="/configuraciones/documentos/tipos/paginador"
                      permisos="CON"
                      component={PaginadorTipoDocumentos}
                    />
                    <RutaSegura
                      exact
                      path="/configuraciones/documentos/clasificaciones/paginador"
                      permisos="CON"
                      component={PaginadorClasificacionDocumentos}
                    />
                    <RutaSegura
                      exact
                      path="/configuraciones/documentos/organismosEmisores/paginador"
                      permisos="CON"
                      component={PaginadorOrganismoEmisores}
                    />
                    <RutaSegura
                      exact
                      path="/configuraciones/documentos/estados/paginador"
                      permisos="CON"
                      component={PaginadorEstadoDocumentos}
                    />

                    <RutaSegura
                      exact
                      path="/configuraciones/documentos/origenes/paginador"
                      permisos="CON"
                      component={PaginadorOrigenHallazgos}
                    />
                    <RutaSegura
                      exact
                      path="/configuraciones/documentos/tiposHallazgos/paginador"
                      permisos="CON"
                      component={PaginadorTipoHallazgos}
                    />
                    <RutaSegura
                      exact
                      path="/configuraciones/documentos/emisores/paginador"
                      permisos="CON"
                      component={PaginadorEmisorHallazgos}
                    />

                    <RutaSegura
                      exact
                      path="/configuraciones/oficios/importacion/asignacion"
                      component={ImportadorAsignacionOficios}
                    />

                    <RutaSegura
                      exact
                      path="/configuraciones/organigrama/importacion"
                      component={ImportadorOrganigrama}
                    />
                    
                    <RutaSegura
                      exact
                      path="/configuraciones/oficios/importacion/carga-masiva/opt"
                      permisos="CON"
                      component={ImportadorOficiosOpt}
                    />

                    <RutaSegura
                      exact
                      path="/configuraciones/oficios/masivo/reasignacion"
                      permisos="CON"
                      noRoles={["Lector"]}
                      component={ReasignacionMasivaOficios}
                    />

                    <RutaSegura
                      exact
                      path="/configuraciones/oficios/masivo/asignacion-tema"
                      permisos="CON"
                      noRoles={["Lector"]}
                      component={AsignacionMasivaPorTemaOficios}
                    />
                    
                    <RutaSegura
                      exact
                      path="/configuraciones/oficios/rebaja-masiva"
                      permisos="CON"
                      component={RebajaMasivaOficios}
                    />

                    <RutaSegura
                      exact
                      path="/configuraciones/oficios/canalesRecepcion/paginador"
                      permisos="CON"
                      component={PaginadorCanalRecepcion}
                    />
                    <RutaSegura
                      exact
                      path="/configuraciones/oficios/canalesEnvio/paginador"
                      permisos="CON"
                      component={PaginadorCanalEnvio}
                    />
                    <RutaSegura
                      exact
                      path="/configuraciones/oficios/identificadoresCausas/paginador"
                      permisos="CON"
                      component={PaginadorIdentificadoresTipoCausa}
                    />
                    <RutaSegura
                      exact
                      path="/configuraciones/oficios/cargos/paginador"
                      permisos="CON"
                      component={PaginadorCargos}
                    />
                    <RutaSegura
                      exact
                      path="/configuraciones/oficios/tiposRequerimientos/paginador"
                      permisos="CON"
                      component={PaginadorTiposRequerimientos}
                    />
                    <RutaSegura
                      exact
                      path="/configuraciones/oficios/oficinas/paginador"
                      permisos="CON"
                      component={PaginadorOficinas}
                    />
                    <RutaSegura
                      exact
                      path="/configuraciones/oficios/tipos/paginador"
                      permisos="CON"
                      component={PaginadorTipoOficios}
                    />
                    <RutaSegura
                      exact
                      path="/configuraciones/oficios/estados/paginador"
                      permisos="CON"
                      component={PaginadorEstadoOficio}
                    />
                    <RutaSegura
                      exact
                      path="/configuraciones/oficios/materias/paginador"
                      permisos="CON"
                      component={PaginadorMaterias}
                    />
                    <RutaSegura
                      exact
                      path="/configuraciones/oficios/delitos/paginador"
                      permisos="CON"
                      component={PaginadorDelitos}
                    />
                    <RutaSegura
                      exact
                      path="/configuraciones/oficios/relacionUsuarioMaterias/paginador"
                      permisos="CON"
                      component={PaginadorRelacionUsuarioMateria}
                    />
                    <RutaSegura
                      exact
                      path="/configuraciones/oficios/relacionUsuarioMateriaGerencias/paginador"
                      permisos="CON"
                      component={PaginadorRelacionUsuarioMateriaGerencia}
                    />
                    <RutaSegura
                      exact
                      path="/configuraciones/procesos/ciclos/paginador"
                      permisos="CON"
                      component={PaginadorProcesoNivel0}
                    />
                    <RutaSegura
                      exact
                      path="/configuraciones/procesos/nivel1/paginador"
                      permisos="CON"
                      component={PaginadorProcesoNivel1}
                    />
                    <RutaSegura
                      exact
                      path="/configuraciones/procesos/nivel2/paginador"
                      permisos="CON"
                      component={PaginadorProcesoNivel2}
                    />
                    <RutaSegura
                      exact
                      path="/configuraciones/procesos/nivel3/paginador"
                      permisos="CON"
                      component={PaginadorProcesoNivel3}
                    />
                    <RutaSegura
                      exact
                      path="/configuraciones/procesos/nivel4/paginador"
                      permisos="CON"
                      component={PaginadorProcesoNivel4}
                    />
                    <RutaSegura
                      exact
                      path="/configuraciones/actividades/consecuencias/paginador"
                      permisos="CON"
                      component={PaginadorConsecuencias}
                    />
                    <RutaSegura
                      exact
                      path="/configuraciones/actividades/tiposAtrasos/paginador"
                      permisos="CON"
                      component={PaginadorTipoAtrasos}
                    />
                    <RutaSegura
                      exact
                      path="/configuraciones/actividades/masivo/reasignacion"
                      permisos="CON"
                      noRoles={["Lector"]}
                      component={ReasignacionMasivaActividades}
                    />
                    <RutaSegura
                      exact
                      path="/configuraciones/actividades/masivo/cierre"
                      permisos="CON"
                      noRoles={["Lector"]}
                      component={CierreMasivoActividades}
                    />
                    <RutaSegura
                      exact
                      path="/configuraciones/feriados/paginador"
                      permisos="CON"
                      component={PaginadorFeriados}
                    />
                    <RutaSegura
                      exact
                      path="/configuraciones/empresas/empresas/paginador"
                      permisos="CON"
                      component={PaginadorEmpresas}
                    />
                    <RutaSegura
                      exact
                      path="/configuraciones/empresas/gerencias/paginador"
                      permisos="CON"
                      component={PaginadorGerencias}
                    />
                    <RutaSegura
                      exact
                      path="/configuraciones/empresas/areas/paginador"
                      permisos="CON"
                      component={PaginadorAreaEmpresas}
                    />
                    <RutaSegura
                      exact
                      path="/configuraciones/empresas/relaciones/paginador"
                      permisos="CON"
                      component={PaginadorRelacionGerencia}
                    />
                    <RutaSegura
                      exact
                      path="/configuraciones/organigrama/arbol"
                      permisos="CON"
                      component={ArbolOrganigrama}
                    />
                    <RutaSegura
                      exact
                      path="/configuraciones/organigrama/cargos/paginador"
                      permisos="CON"
                      component={PaginadorOrgCargos}
                    />
                    <RutaSegura
                      exact
                      path="/configuraciones/assessment/plantillas-evaluaciones/paginador"
                      permisos="CON"
                      component={PaginadorPlantillasEvaluaciones}
                    />
                    
                    <RutaSegura
                      exact
                      path="/configuraciones/activosInformacion/formatos/paginador"
                      permisos="CON"
                      component={PaginadorFormatoActivos}
                    />

                    <RutaSegura
                      exact
                      path="/configuraciones/activosInformacion/tipos/paginador"
                      permisos="CON"
                      component={PaginadorTipoActivos}
                    />
                    
                    <RutaSegura
                      exact
                      path="/configuraciones/activosInformacion/ubicaciones/paginador"
                      permisos="CON"
                      component={PaginadorUbicacionActivos}
                    />
                    <RutaSegura
                      exact
                      path="/configuraciones/riesgos/tipos/paginador"
                      permisos="CON"
                      component={PaginadorTipoRiesgos}
                    />
                    <RutaSegura
                      exact
                      path="/configuraciones/riesgos/sub-tipos/paginador"
                      permisos="CON"
                      component={PaginadorSubTipoRiesgos}
                    />
                    <RutaSegura
                      exact
                      path="/configuraciones/riesgos/factores/paginador"
                      permisos="CON"
                      component={PaginadorFactorIndice}
                    />
                    <RutaSegura
                      exact
                      path="/configuraciones/riesgos/plantillas/controles/paginador"
                      permisos="CON"
                      component={PaginadorPlantillaControles}
                    />
                    <RutaSegura
                      exact
                      permisos="CON"
                      path="/configuraciones/riesgos/importacion"
                      component={ImportadorProcesoRiesgos}
                    />
                    <RutaSegura
                      exact
                      permisos="CON"
                      path="/configuraciones/indicadores/importacion"
                      component={ImportadorIndicadores}
                    />
                    <RutaSegura
                      exact
                      permisos="CON"
                      path="/configuraciones/eventosRiesgo/importacion"
                      component={ImportadorEventosRiesgos}
                    />
                    <RutaSegura 
                      exact
                      permisos="CON"
                      path="/configuraciones/eventosRiesgo/estados/paginador"
                      component={PaginadorEstadosEventoRiesgos}
                    />
                    <RutaSegura 
                      exact
                      permisos="CON"
                      path="/configuraciones/planesAccion/estados/paginador"
                      component={PaginadorEstadosPlanesAccion}
                    />
                    <RutaSegura
                      exact
                      permisos="CON"
                      path="/configuraciones/cumplimiento/indicadores/importacion"
                      component={ImportadorIndicadoresCumpl}
                    />
                    <RutaSegura
                      exact
                      permisos="CON"
                      path="/configuraciones/planesAccion/importacion"
                      component={ImportadorPlanesAccion}
                    />
                    <RutaSegura
                      exact
                      permisos="CON"
                      path="/configuraciones/cumplimiento/importacion"
                      component={ImportadorCumplimientos}
                    />
                    <RutaSegura
                      exact
                      path="/configuraciones/general/bibliotecas"
                      permisos="CON"
                      component={ConfigBiblioteca}
                    />
                    <RutaSegura
                      exact
                      path="/configuraciones/general/generales"
                      permisos="CON"
                      component={ConfigGeneral}
                    />
                    <RutaSegura
                      exact
                      path="/configuraciones/general/notificaciones"
                      permisos="CON"
                      component={ConfigNotificaciones}
                    />
                    <RutaSegura
                      exact
                      path="/configuraciones/perfilesAvisos/paginador"
                      permisos="CON"
                      component={PaginadorPerfilEnvioCorreo}
                    />
                    <RutaSegura
                      exact
                      path="/configuraciones/importacion/doc-comp"
                      permisos="CON"
                      component={ImportadorDocComp}
                    />

                    <RutaSegura
                      exact
                      path="/configuraciones/regiones/regiones/paginador"
                      permisos="CON"
                      component={PaginadorRegiones}
                    />
                    <RutaSegura
                      exact
                      path="/configuraciones/registros/historialCorreos/paginador"
                      permisos="CON"
                      component={PaginadorHistorialEnviosCorreos}
                    />
                    <RutaSegura
                      exact
                      path="/configuraciones/registros/oficios/automatizacion/paginador"
                      permisos="CON"
                      component={PaginadorLogsOficiosAuto}
                    />

                    <RutaSegura
                      exact
                      path="/configuraciones/timeReport/clientes/paginador"
                      permisos="CON"
                      component={PaginadorAsisClientes}
                    />

                    <RutaSegura
                      exact
                      path="/configuraciones/timeReport/etapas/paginador"
                      permisos="CON"
                      component={PaginadorAsisEtapas}
                    />

                    <RutaSegura
                      exact
                      path="/configuraciones/timeReport/tiposProyecto/paginador"
                      permisos="CON"
                      component={PaginadorAsisTiposProyecto}
                    />

                    <RutaSegura
                      exact
                      path="/configuraciones/timeReport/proyectos/paginador"
                      permisos="CON"
                      component={PaginadorAsisProyectos}
                    />

                    <RutaSegura
                      exact
                      path="/configuraciones/timeReport/empresas/relaciones/paginador"
                      permisos="CON"
                      component={PaginadorAsisRelacionEmpresaUsuario}
                    />

                    <RutaSegura
                      exact
                      path="/configuraciones/timeReport/horarios/paginador"
                      permisos="CON"
                      component={PaginadorAsisHorarios}
                    />

                    <RutaSegura
                      exact
                      path="/configuraciones/auditoria/plantillas/paginador"
                      permisos="CON"
                      component={PaginadorAudPlantillas}
                    />
                    
                    <RutaSegura
                      exact
                      path="/configuraciones/auditoria/v2/areas"
                      permisos="CON"
                      component={PaginadorAdtAreas}
                    />

                    <RutaSegura
                      exact
                      path="/configuraciones/auditoria/v2/tiposTrabajo"
                      permisos="CON"
                      component={PaginadorAdtTiposTrabajoAuditoria}
                    />

                    <RutaSegura
                      exact
                      path="/configuraciones/auditoria/v2/tiposAuditoria"
                      permisos="CON"
                      component={PaginadorAdtTiposAuditoria}
                    />

                    <RutaSegura
                      exact
                      path="/configuraciones/auditoria/v2/criticidadesHallazgo"
                      permisos="CON"
                      component={PaginadorAdtCriticidadesHallazgos}
                    />

                    <RutaSegura
                      exact
                      path="/configuraciones/auditoria/v2/ciclos/paginador"
                      permisos="CON"
                      component={PaginadorAdtCiclos}
                    />

                    <RutaSegura
                      exact 
                      path="/configuraciones/auditoria/v2/importacion/auditorias"
                      permisos="CON"
                      component={ImportadorAuditorias}
                    />

                    <RutaSegura
                      exact
                      path="/configuraciones/auditoria/v2/importacion/act-preliminares"
                      permisos="CON"
                      component={ImportadorAdtActPreliminares}
                    />
                    
                    <RutaSegura
                      exact 
                      path="/configuraciones/auditoria/v2/importacion/reglas-adt"
                      permisos="CON"
                      component={ImportadorReglasAuditoria}
                    />

                    <RutaSegura
                      exact
                      path="/configuraciones/auditoria/v2/actualizacion/reglas-adt"
                      permisos="CON"
                      component={ActualizadorReglasAuditoria}
                    />
                    
                    <RutaSegura
                      exact 
                      path="/reportes/rep-rechazadas"
                      roles={["Visador"]}
                      component={RepeticionesRechazadas}
                    />

                    <RutaSegura
                      exact
                      path="/reportes/proximos-vencimientos"
                      permisos="INF"
                      component={ProximosVencimientos}
                    />
                    <RutaSegura
                      exact
                      path="/reportes/vencidas"
                      permisos="INF"
                      component={Vencidas}
                    />
                    <RutaSegura
                      exact
                      path="/reportes/exportacion/1"
                      permisos="INF"
                      component={ExportadorXls}
                    />
                    <RutaSegura
                      exact
                      path="/reportes/exportacion/2"
                      permisos="INR"
                      component={ExportadorXls}
                    />
                    <RutaSegura
                      exact
                      path="/reportes/exportacion/3"
                      permisos="INO"
                      component={ExportadorXls}
                    />
                    <RutaSegura
                      exact
                      path="/reportes/cumplimiento"
                      permisos="INF"
                      component={ReporteCumplimiento}
                    />
                    <RutaSegura
                      exact
                      path="/reportes/documento"
                      permisos="INF"
                      component={ReporteDocumento}
                    />
                    <RutaSegura
                      exact
                      path="/reportes/riesgos"
                      permisos="INR"
                      component={ReporteRiesgos}
                    />

                    <RutaSegura
                      exact
                      path="/reportes/eventosRiesgos"
                      permisos="INR"
                      component={ReporteEventosRiesgos}
                    />
                    <RutaSegura
                      exact
                      path="/reportes/indicadores"
                      permisos="INR"
                      component={ReporteIndicadores}
                    />
                    <RutaSegura
                      exact
                      path="/reportes/oficios"
                      permisos="INO"
                      component={ReporteOficios}
                    />
                    <RutaSegura
                      exact
                      path="/reportes/controles"
                      permisos="INR"
                      component={ReporteControles}
                    />
                    <RutaSegura
                      exact
                      path="/reportes/planesaccion"
                      permisos="INR"
                      component={ReportePlanesAccion}
                    />
                    <RutaSegura
                      exact
                      path="/reportes/atrasos-oficios"
                      permisos="INO"
                      component={AtrasosOficios}
                    />
                    <RutaSegura
                      exact
                      path="/reportes/activos"
                      permisos="ACI"
                      component={ReporteActivos}
                    />
                    <RutaSegura
                      exact
                      path="/reportes/reglas-auditoria"
                      permisos="EAD"
                      component={ReporteReglasAuditoria}
                    />
                    <RutaSegura
                      exact
                      path="/misreglasauditoria/paginador"
                      roles={["Revisor auditoria"]}
                      component={PaginadorMisReglasAuditoria}
                    />
                    <RutaSegura
                      exact
                      path="/solicitud-prorroga/mis-prorrogas/paginador"
                      roles={["Gerente", "Gerente general", "Gerente auditoría"]}
                      component={PaginadorMisSolicitudesProrroga}
                    />

                    <RutaSegura
                      exact
                      path="/assessment/paginador"
                      permisos="AST"
                      component={PaginadorEvaluaciones}
                    />
                    <RutaSegura
                      exact
                      path="/assessment/mis-evaluaciones/paginador"
                      permisos="MST"
                      roles={["Visador assessment", "Ejecutor assessment"]}
                      component={PaginadorMisEvaluaciones}
                    />
                    <RutaSegura
                      exact
                      path="/reportes/assessment"
                      permisos="AST"
                      component={ReporteAssessment}
                    />
                    <RutaDefault />
                  </Switch>
                </Grid>
              </Suspense>
              <CookieConsent
                buttonText="Aceptar"
                style={{ zIndex: 1201 }}
              >Privacidad y cookies: este sitio utiliza cookies. Al continuar utilizando esta web, aceptas su uso.
Para obtener más información, consulta aquí: <a href="https://www.austranet.com/index.php/politica-de-privacidad/" target="_blank" rel="noreferrer" style={{ color: "white", fontWeight: "bold" }}>Políticas</a></CookieConsent>
            </div>
          </Router>
        </ThemeProvider>
      }
    </>
  );
}

export default App;
