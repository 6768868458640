export const TokenService = {

    getLocalAccessToken() {
        return window.localStorage.getItem('tknId');
    },

    getLocalRefreshToken() {
        return window.localStorage.getItem('grantType');
    },

    setLocalTokens(token, refreshToken) {
        window.localStorage.setItem('tknId', token);
        window.localStorage.setItem('grantType', refreshToken);
    },

    removeTokens() {
        window.localStorage.removeItem("tknId");
        window.localStorage.removeItem("grantType");
    }
}

export default TokenService;