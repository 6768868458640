// React
import React from 'react';
// Material-UI
import { Container, Divider, Grid, Paper, Typography } from '@material-ui/core';
import { useLocalization } from 'contexto/localization/useLocalization';
// Otras libs

// Componentes

// Vistas

// Servicios

// Funciones

// Hooks


const NoPermitido = () => {
    // Idioma
    const { strings } = useLocalization();
    // Token

    // Estados

    // Parametros URL


    // Paginador

    // Listas

    // Funciones

    // Otros


    // Vista
    return (
        <Container maxWidth="md" justify="center">
            <Grid container style={{ paddingTop: 30 }}>
                <Grid item xs={12} md={12}>
                    <Typography variant="h6" gutterBottom style={{ marginBottom: 20 }}>
                        {strings.modules.areaRestringida}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                    <Paper elevation={0} style={{ boxShadow: "rgb(209 209 209 / 25%) 0px 1px 4px, rgb(209 209 209 / 35%) 0px 3px 12px 2px" }}>
                        <Grid container spacing={1} style={{ padding: 20 }}>
                            <Grid item xs={12} md={12}>
                                <Typography variant="subtitle2" gutterBottom style={{ fontSize: 17 }}>
                                    {strings.modules.noPermitido}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12} md={12} style={{ paddingTop: 15 }}>
                                <Typography variant="subtitle2" gutterBottom style={{ fontSize: 17 }}>
                                    {strings.modules.noTienePermisosSufientesAccesoPagina}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
}

export default NoPermitido;