// React
import React, { useEffect, useState } from 'react';
// Material-UI
import { Container, Grid } from '@material-ui/core';
// Otras libs

// Componentes
import { CustomModal } from 'componentes/CustomModal';
import { marcarNotificacionComoLeida } from 'actions/UsuarioAction';
import LoadingOrError from 'componentes/LoadAndShow';
// Vistas

// Servicios

// Funciones

// Hooks


export default function ModalVerNotificacion({ notifId, titulo, open, onClose = () => {}, onRead = () => {} }) {
    // Idioma
    
    // Token

    // Estados 
    const [state, setState] = useState({
        notificacion: undefined,
        loading: true,
        error: false,
    });
    // Paginador

    // Listas

    // Funciones
    
    // Otros
    useEffect(() => {
        marcarNotificacionComoLeida(notifId).then(([res, err]) => {
            if (res && res.data) {
                setState({
                    notificacion: res.data,
                    loading: false,
                    error: false,
                });
                onRead();
            } else {
                setState({
                    notificacion: undefined,
                    loading: false,
                    error: true
                });
            }
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notifId]);


    // Vista
    return (
        <CustomModal
            title={titulo}
            open={open}
            onClose={onClose}
            actions={undefined}
            padding={0}
            maxWidth="md"
            fullWidth
        >
            {(state.loading || state.error) && (
                <Container maxWidth="lg" justify="center" style={{ padding: 0 }}>
                    <Grid container>
                        <Grid item xs={12} md={12}>
                            <LoadingOrError
                                isLoading={state.loading}
                                hasError={state.error}
                            />
                        </Grid>
                    </Grid>
                </Container>
            )}
            {!state.loading && !state.error && (
                <Container maxWidth="md" justify="center" style={{ padding: 0 }}>
                    <Grid container>
                        <Grid item xs={12} md={12} container spacing={2} alignItems="center" style={{ margin: 10 }}>
                            <div dangerouslySetInnerHTML={{__html: state.notificacion.correo }} style={{ display: "contents" }}/>
                        </Grid>
                    </Grid>
                </Container>
            )}
        </CustomModal>
    );
}